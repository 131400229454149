import datatables_initialize from "../datatables_initialize";
import yadcf_wrapper from "../yadcf_wrapper";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    super.connect();

    const tableElement = $(this.element).find("table.dataTable");
    const tableheaders = Array.from(
      this.element.querySelectorAll("table.dataTable th"),
    );

    if (!tableElement || tableElement.length == 0) {
      console.warn("DataTable not found!");
      return;
    }

    const dataTable = datatables_initialize(tableElement);

    const filters = tableheaders.map((th) => {
      if (th.dataset === undefined || th.dataset["yadcf"] === undefined) {
        return {};
      }
      return JSON.parse(th.dataset["yadcf"]);
    });
    yadcf_wrapper(dataTable, filters);

    document.addEventListener("turbo:before-cache", () => {
      dataTable.destroy();
    });
  }
}
